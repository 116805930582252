import styled from 'styled-components';

export const CurrentSliderNumberWrapper = styled.div`
  position: relative;
  padding: 8px 6px;
  mask-composite: exclude;
  width: fit-content;
  height: fit-content;
  color: ${({theme}) => theme.colors.brandGreenV2};
  font-weight: 500;
  font-size: 7px;
  letter-spacing: 0.1px;
  &:before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 70px;
    padding: 1.5px;
    background: linear-gradient(308.11deg, #00755a 57.31%, rgba(0, 117, 90, 0) 82.97%)
      border-box;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`;
