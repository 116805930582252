import {IUTP} from '@api/types/MainPage';
import Button from '@components/atoms/Button';
import {GlobalContainer} from '@styles/pages/_app.styles';
import Image from 'next/legacy/image';
import {useRouter} from 'next/router';
import {FC} from 'react';
import HtmlParser from 'react-html-parser';
import {AnalyticEventCategories, AnalyticEventNames} from '@api/types/AnalyticEvents';
import { sendClientParamsNATS, scrollToContainer } from '@utils/common';
import {
  ButtonWrapper,
  LeasingTermsCard,
  LeasingTermsCardImg,
  LeasingTermsCards,
  LeasingTermsCardText,
  LeasingTermsCardTitle,
  LeasingTermsCardWrapper,
  LeasingTermsTitle,
  LeasingTermsWrapper,
} from './LeasingTerms.styles';

interface LeasingTermsProps {
  nodeRef?: React.MutableRefObject<any>;
  utps: IUTP[];
}
const LeasingTerms: FC<LeasingTermsProps> = ({nodeRef, utps}) => {

  return (
    <LeasingTermsWrapper ref={nodeRef}>
      <GlobalContainer>
        <LeasingTermsTitle>
          <h2>Условия льготного лизинга</h2>
        </LeasingTermsTitle>
        <LeasingTermsCards>
          <LeasingTermsCardWrapper>
            {utps.map((item: IUTP) => (
              <LeasingTermsCard key={item.id}>
                <LeasingTermsCardImg>
                  <Image
                    src={item.icon}
                    layout="responsive"
                    width={28}
                    height={28}
                    alt={item.title}
                  />
                </LeasingTermsCardImg>
                <LeasingTermsCardTitle>{item.title}</LeasingTermsCardTitle>
                <LeasingTermsCardText>{HtmlParser(item.text)}</LeasingTermsCardText>
              </LeasingTermsCard>
            ))}
          </LeasingTermsCardWrapper>
          <ButtonWrapper>
            <Button
              title="Оставить заявку"
              variant="filled"
              style={{fontSize: '16px', padding: '15px 61px'}}
              onClick={() => {
                scrollToContainer('#applicationBlockForm');
                sendClientParamsNATS(AnalyticEventNames.MainPageClickLeaveApplication, {
                  eventName: AnalyticEventNames.MainPageClickLeaveApplication,
                  eventCategory: AnalyticEventCategories.MainPage,
                  eventParams: {
                    block: 'preferential-leasing'
                  }
                })
              }}
            />
          </ButtonWrapper>
        </LeasingTermsCards>
      </GlobalContainer>
    </LeasingTermsWrapper>
  );
};

export default LeasingTerms;
