import BlockTitle from '@components/atoms/BlockTitle';
import Button from '@components/atoms/Button';
import {theme} from '@styles/theme';
import styled from 'styled-components';

export const OnlineServicesBlockWrapper = styled.div`
  margin: 80px 0;

  @media (max-width: 768px) {
    margin: 50px 0;
  }
`;

export const OnlineServicesBlockTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const OnlineServicesBlockText = styled.div`
  font-size: 18px;
  color: ${({theme}) => theme.colors.gray};

  @media (max-width: 768px) {
    font-size: 16px;
    max-width: 70%;
    line-height: 18px;
  }
  @media (max-width: 560px) {
    max-width: 100%;
    font-size: 15px;
  }
`;

export const OnlineServicesItems = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin-top: 50px;
  @media (max-width: 996px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 768px) {
    margin-top: 30px;
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 30px;
  }
`;

export const StyledTitle = styled(BlockTitle)`
  color: ${theme.colors.darkText};
  letter-spacing: '-0.25 px';

  @media (max-width: 768px) {
    font-size: 24px;
  }
  @media (max-width: 560px) {
    font-size: 20px;
  }
`;

export const StyledButton = styled(Button)`
  padding: 10px 18px;
  font-weight: 500;
`;

export const MobileButton = styled(StyledButton)`
  width: 100%;
  height: 40px;
  @media (min-width: 601px) {
    display: none;
  }
`;

export const DesktopButton = styled(StyledButton)`
  height: 40px;
  @media (max-width: 600px) {
    display: none;
    gap: 20px;
    margin-top: 41px;
  }
  @media (max-width: 996px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 510px) {
    grid-template-columns: 1fr;
  }
`;
