import Image from 'next/legacy/image';
import Link from 'next/link';
import {FC} from 'react';
import {
  CatalogPreviewItemCount,
  CatalogPreviewItemImg,
  CatalogPreviewItemTitle,
  CatalogPreviewItemWrapper,
  Line,
} from './CatalogPreviewItem.styles';

interface CatalogPreviewItemProps {
  iconSrc: string | undefined;
  name: string | undefined;
  count: string | number | undefined;
  id: number | undefined;
  active?: boolean;
  uri?: string;
  type: 'leasingCatalog' | 'saleCatalog';
  params?: Record<string, any>;
}

const CatalogPreviewItem: FC<CatalogPreviewItemProps> = ({
  iconSrc,
  name,
  id,
  count,
  active,
  type = 'leasingCatalog',
  uri,
  params = {},
}) => {
  const href = type === 'leasingCatalog' 
    ? {
      pathname: `/catalog/${uri ? uri : id}`,
      query: params
    } 
    : {
      pathname: `/sale/catalog/`,
      query: {
        ...params,
        'type/id': id
      }
    };
  return (
    <Link
      href={href}>
      <CatalogPreviewItemWrapper>
        <CatalogPreviewItemImg>
          <Image
            src={
              iconSrc && iconSrc.length !== 0
                ? iconSrc
                : '/assets/icons/catalogItemNoPhoto.svg'
            }
            layout="fill"
            objectFit="contain"
            alt={`Изображение ${name}`}
            title={name}
          />
          <CatalogPreviewItemCount active={active}>{count}</CatalogPreviewItemCount>
        </CatalogPreviewItemImg>
        <CatalogPreviewItemTitle active={active}>{name}</CatalogPreviewItemTitle>
        {active && <Line />}
      </CatalogPreviewItemWrapper>
    </Link>
  );
};

export default CatalogPreviewItem;
