import {getBorderConditions} from '@api/catalog';
import {getCalcResult} from '@api/main';
import {
  CalcGetPaymentOptions,
  CalcValues,
  KeysOf,
  SelectOptions,
} from '@api/types/Catalog';
import {CalcSettings, Competitor} from '@api/types/CatalogProduct';
import ProductCardCalcAutoSave from '@components/atoms/ProductCardCalcAutoSave';
import {CalcResultContext} from '@utils/contexts';
import {divideNumber, yearsToStr} from '@utils/formatters';
import {Formik} from 'formik';
import {debounce} from 'lodash';
import {FC, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {
  FormWrapper,
  PriceRangeInputMinMax,
  RangeInputWrapper,
  StyledRangeInput,
  InputsGrid,
} from './MainPageCompactCalcForm.styles';
import {trackEvent} from '@utils/analytics';
import {AnalyticEventCategories, AnalyticEventNames} from '@api/types/AnalyticEvents';

interface MainPageCompactCalcFormProps {
  calcSettings: CalcSettings;
  relevantCompetitors: Competitor[];
  testID?: string;
  categories: Array<{title: string; value: string}> | any;
  testSubmit?: (values: any) => any;
  setCalcResultLoading?: (values: boolean) => void;
  background?: string;
}

const minPrice = 1000000;
const maxPrice = 50000000;

const MainPageCompactCalcForm: FC<MainPageCompactCalcFormProps> = ({
  calcSettings,
  setCalcResultLoading,
  categories,
  background = ''
}) => {
  const [paymentOptions, setPaymentOptions] = useState<SelectOptions[]>([
    {title: 'Не выбрано', value: '0'},
  ]);
  const [advPayMinAndTermMax, setAdvPayMinAndTermMax] = useState({
    advPayMin: +calcSettings.min_advance_pay,
    termMax: +calcSettings.max_term_years,
  });

  const [defaultValuesState, setDefaultValuesState] = useState(false);

  const [initialValues, setInitialValues] = useState({
    category: '0',
    repayment_type: +paymentOptions[0].value,
    advance_payment: 10,
    term_of_leasing: 5,
    price: 15000000,
    quantity: 1,
    isassociation: false,
    isshtp: true,
  });

  const formikRef: any = useRef(null);

  const [calcResult, setCalcResult] = useContext(CalcResultContext);
  const [changedByUser, setChangedByUser] = useState(false);

  const onSubmitCalc = useCallback(
    debounce(async (values: CalcValues) => {
      for await (const field of Object.keys(initialValues)) {
        document.getElementById(field)?.blur();
      }
      setCalcResultLoading && setCalcResultLoading(true);
      const result = await getCalcResult(values);
      setCalcResult && setCalcResult(result);
      setCalcResultLoading && setCalcResultLoading(false);

      if (changedByUser) {
        trackEvent(
          AnalyticEventCategories.MainPage,
          AnalyticEventNames.MainPageCalcNewResult,
          {
            type: 'small',
          },
        );
      }
    }, 250),
    [changedByUser],
  );

  async function onTechChange(value: any) {
    const fieldKeys: KeysOf<CalcGetPaymentOptions>[] = [
      'advancePay',
      'orderPeriodYears',
      'isAnnuitet',
      'isRegress',
      'isSeasonality',
    ];

    const res = await getBorderConditions(value);

    const newOptions: SelectOptions[] = [];

    setAdvPayMinAndTermMax({
      advPayMin: res.advancePay ?? +calcSettings.min_advance_pay,
      termMax: res.orderPeriodYears ?? +calcSettings.max_term_years,
    });

    if (value === '0') {
      setAdvPayMinAndTermMax({
        advPayMin: +calcSettings.min_advance_pay,
        termMax: +calcSettings.max_term_years,
      });
    }

    for (const i of fieldKeys) {
      if (res[i] === '1') {
        if (i === 'isAnnuitet') {
          newOptions.push({title: 'Аннуитет', value: '1' as string});
        }
        if (i === 'isRegress') {
          newOptions.push({title: 'Регресс', value: '2' as string});
        }
        if (i === 'isSeasonality') {
          newOptions.push({title: 'Сезонный', value: '3' as string});
        }
      }
    }

    if (newOptions.length === 0) {
      setPaymentOptions([{title: 'Не выбрано', value: '0'}]);
      return;
    } else {
      setPaymentOptions([...newOptions]);
    }
    formikRef.current.setFieldValue('category', value);
  }

  useEffect(() => {
    if (categories.length && !defaultValuesState) {
      onTechChange(categories[0].value);
      setDefaultValuesState(true);
    }
  }, [categories]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={onSubmitCalc}
      enableReinitialize>
      {({handleSubmit, handleChange, values, setFieldValue}) => (
        <form
          onSubmit={handleSubmit}
          style={{position: 'relative'}}
          onChange={() => {
            !changedByUser && setChangedByUser(true);
          }}>
          <ProductCardCalcAutoSave />
          <FormWrapper>
            <InputsGrid>
              <RangeInputWrapper>
                <StyledRangeInput
                  id="price"
                  label="Стоимость техники"
                  min={minPrice}
                  max={maxPrice}
                  onChange={value => setFieldValue('price', value)}
                  value={values.price}
                  suffix={' ₽'}
                />
                <PriceRangeInputMinMax>
                  <p>{divideNumber(minPrice)} ₽</p>
                  <p>{divideNumber(maxPrice)} ₽</p>
                </PriceRangeInputMinMax>
              </RangeInputWrapper>
              <RangeInputWrapper>
                <StyledRangeInput
                  id="term_of_leasing"
                  label="Срок договора"
                  min={1}
                  max={
                    advPayMinAndTermMax.termMax === 0
                      ? +calcSettings.max_term_years
                      : advPayMinAndTermMax.termMax
                  }
                  onChange={value => setFieldValue('term_of_leasing', value)}
                  value={values.term_of_leasing}
                  suffix={` ${yearsToStr(values.term_of_leasing)}`}
                />
                <PriceRangeInputMinMax>
                  <p>1 год</p>
                  <p>
                    {`${
                      advPayMinAndTermMax.termMax === 0
                        ? +calcSettings.max_term_years
                        : advPayMinAndTermMax.termMax
                    }
                  ${' '}
                  ${yearsToStr(
                    advPayMinAndTermMax.termMax === 0
                      ? +calcSettings.max_term_years
                      : advPayMinAndTermMax.termMax,
                  )}`}
                  </p>
                </PriceRangeInputMinMax>
              </RangeInputWrapper>
            </InputsGrid>
          </FormWrapper>
        </form>
      )}
    </Formik>
  );
};

export default MainPageCompactCalcForm;
