import Button from '@components/atoms/Button';
import {theme} from '@styles/theme';
import styled from 'styled-components';

export const SpecialOffersWrapper = styled.div`
  margin: 80px 0;

  @media (max-width: 768px) {
    margin: 50px 0;
  }
  @media (max-width: 550px) {
    margin-bottom: 90px;
  }
`;

export const SpecialOffersTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

export const SpecialOffersTitle = styled.h2`
  font-family: ${theme.fonts.headline};
  font-size: 36px;
  letter-spacing: -0.25px;
  color: ${theme.colors.darkText};

  @media (max-width: 768px) {
    font-size: 24px;
  }
  @media (max-width: 550px) {
    font-size: 20px;
  }
`;

export const SpecialOffersText = styled.div`
  font-size: 18px;
  color: ${({theme}) => theme.colors.gray};
  margin-bottom: 50px;
  line-height: 21px;

  @media (max-width: 960px) {
    max-width: 65%;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 30px;
  }
  @media (max-width: 550px) {
    max-width: 100%;
    font-size: 15px;
    line-height: 17px;
  }
`;

export const SpecialOffersItems = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 33px;
  border-radius: 4px;

  @media (max-width: 1150px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }

  @media (max-width: 550px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledButton = styled(Button)`
  display: block;
  font-weight: 500;
  line-height: 20px;
  height: 40px;
  margin-bottom: -50px;
`;

export const MobileButton = styled(StyledButton)`
  width: 100%;
  height: 40px;
  @media (min-width: 551px) {
    display: none;
  }
`;

export const DesktopButton = styled(StyledButton)`
  @media (max-width: 550px) {
    display: none;
  }
`;
