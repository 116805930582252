import { GlobalContainer } from '@styles/pages/_app.styles';
import { theme } from '@styles/theme';
import Link from 'next/link';
import styled from 'styled-components';

export const SliderWrapper = styled.div<{loaded: boolean}>`
  display: flex;
  height: 100% !important;
  overflow: hidden !important;
  align-items: inherit;
  opacity: ${({loaded}) => (loaded ? '1' : '0')};
  visibility: ${({loaded}) => (loaded ? 'visible' : 'hidden')};
  transition: all 0.5s;
`;

export const SlideWrapper = styled.div`
  width: 100%;
`;

export const CenterWrapper = styled.div<{$isTwoSlidesView: boolean}>`
  position: absolute;
  width: 100%;
  bottom: 8%;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 1135px) {
    bottom: 3%;
  }

  @media (max-width: 560px) {
    bottom: 4%;
  }

  ${props => props.$isTwoSlidesView && `
      bottom: 5%;
      @media (max-width: 1440px) {
        bottom: 3%;
      }
      @media (max-width: 768px) {
        ${GlobalContainer} {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
  `}
`;


export const AllSpecialOffers = styled(Link)`
  position: absolute;
  right: 39px;
  bottom: 8%;
  z-index: 999;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: ${theme.colors.brandGreenV2};
  display: flex;
  align-items: flex-end;
  &:after {
    content: url('/assets/icons/rightArrowIcon.svg');
    margin-left: 8px;
    margin-top: 3px;
    ${theme.filters.brandGreenV2}
  }

  @media (max-width: 768px) {
    position: relative;
    right: 0;
    bottom: 0;
  }

`;