import {IMainPageSlidesParams} from '@api/types/MainPage';
import {yearsToStr} from '@utils/formatters';
import Image from 'next/legacy/image';
import {FC, useEffect, useRef, useState} from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
  RelativeWrapper,
  SaleBannerContainer,
  SaleBannerCtaWrapper,
  SaleBannerImage,
  SaleBannerInfo,
  SaleBannerText,
  SaleBannerTextItem,
  SaleBannerTextItems,
  SaleBannerTextItemText,
  SaleBannerTextItemTitle,
  SaleBannerTextWrapper,
  SaleBannerTitle,
  SaleBannerWrapper,
  StyledButton,
} from './SaleBanner.styles';
import {useObserver} from '@hooks';
import {trackEvent} from '@utils/analytics';
import {AnalyticEventCategories, AnalyticEventNames} from '@api/types/AnalyticEvents';

interface SaleBannerProps {
  title: string;
  text?: string;
  params?: IMainPageSlidesParams;
  btnText?: string;
  imgSrc: string;
  saleBannerImgStyle?: Record<string, string>;
  imgSize?: {width: number; height: number} | null;
  btnLink?: string;
  isTwoSlidesView?: boolean;
}

const SaleBanner: FC<SaleBannerProps> = ({
  title,
  text,
  params,
  btnText = 'Подробнее',
  imgSrc,
  saleBannerImgStyle,
  imgSize = {width: 480, height: 350},
  btnLink,
  isTwoSlidesView = false,
}) => {
  const [mappedParams, setMappedParams] = useState<
    Array<{title: string; value: string} | {title: undefined; value: undefined}>
  >([]);

  const ref = useRef<HTMLDivElement>(null);

  const showBtn = Boolean(btnText && btnLink);

  useObserver([ref.current as Element], async () => {
    trackEvent(AnalyticEventCategories.MainPage, AnalyticEventNames.MainPageBannerShown, {
      banner_name: title,
    });
  });

  useEffect(() => {
    if (params) {
      const mappedParams = [
        params.margin ? {title: params.margin_title, value: `от ${params?.margin}%`} : {},
        params.advpay ? {title: params.advpay_title, value: `от ${params?.advpay}%`} : {},
        params.term
          ? {
              title: params.term_title,
              value: `до ${+params?.term} ${yearsToStr(+params?.term)}`,
            }
          : {},
      ];
      setMappedParams(mappedParams);
    }
  }, [params]);

  return (
    <SaleBannerWrapper ref={ref}>
      <SaleBannerContainer>
        <RelativeWrapper $isTwoSlidesView={isTwoSlidesView}>
          <SaleBannerImage style={saleBannerImgStyle}>
            <Image
              src={imgSrc}
              width={imgSize?.width}
              height={imgSize?.height}
              layout="responsive"
              alt="Sale Banner"
              objectFit="contain"
            />
          </SaleBannerImage>
          <SaleBannerInfo>
            <SaleBannerTextWrapper>
            <SaleBannerTitle>{ReactHtmlParser(title)}</SaleBannerTitle>
            {text && <SaleBannerText>{ReactHtmlParser(text)}</SaleBannerText>}
            {mappedParams && mappedParams.length !== 0 && (
              <SaleBannerTextItems>
                {mappedParams
                  .filter(param => param.title && param.value)
                  .map((param, i) => (
                    <SaleBannerTextItem key={i}>
                      <SaleBannerTextItemTitle>{param.title}</SaleBannerTextItemTitle>
                      <SaleBannerTextItemText>{param.value}</SaleBannerTextItemText>
                    </SaleBannerTextItem>
                  ))}
              </SaleBannerTextItems>
            )}
            </SaleBannerTextWrapper>
            {showBtn && (
              <SaleBannerCtaWrapper>
                <StyledButton
                  title={btnText}
                  variant="outlined"
                  fontSize={14}
                  style={{padding: '10px 18px', fontWeight: '500'}}
                  link={btnLink}
                  onClick={() => {
                    trackEvent(
                      AnalyticEventCategories.MainPage,
                      AnalyticEventNames.MainPageBannerClicked,
                      {
                        banner_name: title,
                      },
                    );
                  }}
                  openLinkBlank
                />
              </SaleBannerCtaWrapper>
            )}
          </SaleBannerInfo>
        </RelativeWrapper>
      </SaleBannerContainer>
    </SaleBannerWrapper>
  );
};

export default SaleBanner;
