import Button from '@components/atoms/Button';
import {GlobalContainer} from '@styles/pages/_app.styles';
import {theme} from '@styles/theme';
import styled from 'styled-components';

export const SaleBannerWrapper = styled.div`
  display: flex;
  background: linear-gradient(
    270deg,
    rgba(0, 117, 90, 0.1) 50%,
    rgba(255, 238, 0, 0.1) 87.92%
  );
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  min-height: 510px;
  @media (max-width: 1068px) {
    min-height: 550px;
  }
`;

export const SaleBannerContainer = styled.div`
  width: 100%;
`;

export const SaleBannerTitle = styled.div`
  font-family: ${theme.fonts.headline};
  font-style: normal;
  font-weight: 400;
  font-size: 52px;
  margin-top: 65px;
  margin-bottom: 20px;
  letter-spacing: -0.25px;
  color: ${theme.colors.darkText};
  span {
    color: ${theme.colors.brandGreenV2};
  }

  @media (max-width: 1135px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    font-size: 40px;
    line-height: 44px;
    margin-top: 65px;
  }
  @media (max-width: 560px) {
    font-size: 28px;
    line-height: 34px;
    margin-top: 0px;
  }
`;

export const SaleBannerText = styled.div`
  font-size: 18px;
  margin-bottom: 26px;
  line-height: 28px;
  color: ${theme.colors.gray};
  @media (max-width: 1135px) {
    width: 90%;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 19px;
  }
  @media (max-width: 560px) {
    width: 100%;
  }
`;
export const SaleBannerTextItems = styled.div`
  display: flex;
  gap: 31px;
  margin-bottom: 30px;
  @media (max-width: 520px) {
    flex-direction: column;
  }
`;

export const SaleBannerTextItem = styled.div``;

export const SaleBannerTextItemTitle = styled.div`
  font-family: ${theme.fonts.headline};
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: ${theme.colors.darkText};
  margin-bottom: 7px;
`;

export const SaleBannerTextItemText = styled.div`
  font-weight: 400;
  font-size: 18px;
  color: ${theme.colors.gray};
  line-height: 21px;
`;

export const SaleBannerImage = styled.div`
  position: static;
  order: 1;
  width: 45%;
  align-self: center;
  z-index: -1;

  @media (max-width: 768px) {
    width: 70%;
  }
  @media (max-width: 740px) {
    order: 0;
    height: fit-content;
    margin-top: 33px;
  }

  @media (max-width: 560px) {
    position: static;
    width: 100%;
  }
`;

export const SaleBannerInfo = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 0px 25px 0;
`;

export const SaleBannerTextWrapper = styled.div``;

export const StyledButton = styled(Button)`
  margin-bottom: 70px;
  @media (max-width: 440px) {
    margin-bottom: 45px;
  }
`;

export const SaleBannerCtaWrapper = styled.div``;

export const RelativeWrapper = styled(GlobalContainer)<{$isTwoSlidesView: boolean}>`
  position: relative;
  display: flex;
  height: 100%;
  @media (max-width: 740px) {
    flex-direction: column;
  }
  ${props =>
    props.$isTwoSlidesView &&
    `
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 40px;
      @media (max-width: 1350px) {
        grid-template-columns: 1fr;
        gap: 20px;
      }
  `}

  ${SaleBannerInfo} {
    ${props =>
      props.$isTwoSlidesView &&
      `
      height: auto;
    `}
  }
  ${SaleBannerCtaWrapper} {
    ${props =>
      props.$isTwoSlidesView &&
      `
        margin-top: auto;
    `}
  }
  ${SaleBannerImage} {
    ${props =>
      props.$isTwoSlidesView &&
      `width: 100%;
       min-width: 45%;
       @media (max-width: 1350px)  {
        width: 40%;
        order: 0;
        height: fit-content;
        margin: 33px auto auto;
      }
        @media (max-width: 768px)  {
          width: 70%;
        }

      `}
  }
  ${SaleBannerTextWrapper} {
    ${props =>
      props.$isTwoSlidesView &&
      `
        @media (min-width: 1350px) {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
    `}
  }
  ${SaleBannerTitle} {
    ${props =>
      props.$isTwoSlidesView &&
      `
      font-size: 36px;
      @media (max-width: 1550px) and (min-width: 769px) {
        font-size: 28px;
      }
      @media (max-width: 1350px){
        margin-top: 0;
      }
      `}
  }
  ${SaleBannerText} {
    ${props =>
      props.$isTwoSlidesView &&
      `
          font-size: 16px;
          line-height: 1.1;
          @media (max-width: 1550px) and (min-width: 769px) {
            font-size: 13px;
          }
      `}
  }
  ${SaleBannerTextItemText} {
    ${props =>
      props.$isTwoSlidesView &&
      `
          font-size: 16px;
          line-height: 1.1;
          @media (max-width: 1550px) and (min-width: 769px) {
            font-size: 13px;
          }
      `}
  }
  ${SaleBannerTextItemTitle} {
    ${props =>
      props.$isTwoSlidesView &&
      `
          font-size: 12px;
      `}
  }
  ${SaleBannerTextItems} {
    ${props =>
      props.$isTwoSlidesView &&
      `
         @media (max-width: 1350px) and (min-width: 769px) {
            flex-direction: column;
            gap: 12px;
          }
          
      `}
  }
`;
