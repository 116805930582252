import styled from 'styled-components';

export const MainPageWrapper = styled.div``;

export const SlideWrapper = styled.div``;

export const AdditionalBlockSlideWrapper = styled.div<{itemsLength: number}>`
  display: flex;
  width: 100%;
  margin-bottom: ${({itemsLength}) => (itemsLength > 2 ? '80px' : '0px')};
`;
