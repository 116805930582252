import ClientServiceCard from '@components/molecules/ClientServices/ClientServiceCard';
import content from '@mockData/appData.json';
import {servicesCardData} from '@mockData/services/servicesCardData';
import {GlobalContainer} from '@styles/pages/_app.styles';
import {AnalyticEventCategories, AnalyticEventNames} from '@api/types/AnalyticEvents';
import { sendClientParamsNATS } from '@utils/common';
import {
  DesktopButton,
  MobileButton,
  OnlineServicesBlockText,
  OnlineServicesBlockTitleWrapper,
  OnlineServicesBlockWrapper,
  OnlineServicesItems,
  StyledTitle,
} from './OnlineServicesBlock.styles';

const OnlineServicesBlock = ({nodeRef}: {nodeRef?: React.MutableRefObject<any>;}) => {
  const link = '/client/services';

  return (
    <OnlineServicesBlockWrapper ref={nodeRef}>
      <GlobalContainer>
        <OnlineServicesBlockTitleWrapper>
          <StyledTitle>Онлайн-сервисы</StyledTitle>
          <DesktopButton title="Все сервисы" variant="outlined" link={link} />
        </OnlineServicesBlockTitleWrapper>
        <OnlineServicesBlockText>
          {content.main.onlineServices.text}
        </OnlineServicesBlockText>
        <OnlineServicesItems>
          {servicesCardData.slice(0, 4).map((card, idx) => (
            <ClientServiceCard
              key={idx}
              text={card.title}
              imgUrl={card.src}
              url={card.href}
              onClick={() => {
                sendClientParamsNATS(AnalyticEventNames.MainPageClickService, {
                  eventName: AnalyticEventNames.MainPageClickService,
                  eventCategory: AnalyticEventCategories.MainPage,
                  eventParams: {
                    serviceTitle: card.title,
                    serviceUrl: card.href
                  }
                })
              }}
            />
          ))}
        </OnlineServicesItems>
        <MobileButton 
          onClick={() => {
            sendClientParamsNATS(AnalyticEventNames.MainPageClickAllServices, {
                eventName: AnalyticEventNames.MainPageClickAllServices,
                eventCategory: AnalyticEventCategories.MainPage,
              })
          }} 
          title="Все сервисы" 
          variant="outlined" 
          link={link} />
      </GlobalContainer>
    </OnlineServicesBlockWrapper>
  );
};

export default OnlineServicesBlock;
