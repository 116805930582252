import BlockTitle from '@components/atoms/BlockTitle';
import {theme} from '@styles/theme';
import styled from 'styled-components';

export const CatalogPreviewWrapper = styled.div`
  margin-top: 80px;

  @media (max-width: 841px) {
    margin-top: 50px;
  }
`;

export const CatalogPreviewTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 65px;

  @media (max-width: 800px) {
    margin-bottom: 50px;
  }
  @media (max-width: 420px) {
    margin-bottom: 40px;
  }
`;

export const CatalogPreviewItems = styled.div`
  display: grid;
  gap: 18px;
  grid-template-columns: repeat(auto-fit, minmax(104px, 1fr));
  margin-bottom: 85px;

  @media (max-width: 800px) {
    margin-bottom: 50px;
  }
`;

export const Title = styled(BlockTitle)`
  color: ${theme.colors.darkText};
  letter-spacing: -0.25px;

  @media (max-width: 768px) {
    font-size: 24px;
  }
  @media (max-width: 560px) {
    font-size: 20px;
  }
`;
