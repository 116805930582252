import {MainPageCategory} from '@api/types/CategoriesT';
import {IUTP} from '@api/types/MainPage';
import CatalogPreview from '@components/molecules/CatalogPreview';
import LeasingTerms from '@components/molecules/LeasingTerms';
import React, {FC} from 'react';
import {LeasTermsAndCatalogPreviewWrapper} from './LeasTermsAndCatalogPreview.styles';
import { NEW_HOMEPAGE } from '@constants/experimentsCodes';
interface LeasTermsAndCatalogPreviewProps {
  categories: MainPageCategory[];
  nodeRefs: {
    catalogPreviewRef: React.MutableRefObject<any>;
    leasingTearmsRef: React.MutableRefObject<any>;
  };
  utps: IUTP[];
  experiments?: string[]
}

const LeasTermsAndCatalogPreview: FC<LeasTermsAndCatalogPreviewProps> = ({
  categories,
  nodeRefs,
  utps,
  experiments = []
}) => {
  const catalogPreviewFirst = experiments.includes('E-511103');
  const isInfiniteCatalogView = experiments.includes(NEW_HOMEPAGE);
  return (
    <LeasTermsAndCatalogPreviewWrapper>
      {catalogPreviewFirst && (
        <CatalogPreview nodeRef={nodeRefs.catalogPreviewRef} categories={categories} />
      )}
      {!isInfiniteCatalogView && !!utps.length && (
        <LeasingTerms nodeRef={nodeRefs.leasingTearmsRef} utps={utps} />
      )}
      {!catalogPreviewFirst && (
        <CatalogPreview nodeRef={nodeRefs.catalogPreviewRef} categories={categories} />
      )}
    </LeasTermsAndCatalogPreviewWrapper>
  );
};

export default LeasTermsAndCatalogPreview;
