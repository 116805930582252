import {ISpecialOffers} from '@api/types/MainPage';
import Image from 'next/legacy/image';
import Link from 'next/link';
import {FC} from 'react';
import HtmlParser from 'react-html-parser';
import {
  Description,
  GoToMore,
  GoToMoreWrapper,
  OfferImage,
  OfferImageContainer,
  OfferInfo,
  SpecOfferItemWrapper,
  Title,
} from './SpecialOffersItem.styles';

interface SpecialOffersItemProps {
  offer?: Partial<ISpecialOffers>;
  style?: Record<string, string>;
  linkText?: string;
  haveID?: boolean;
  onClick?: () => void;
}

const SpecialOffersItem: FC<SpecialOffersItemProps> = ({
  offer,
  style,
  linkText = 'Подробнее',
  haveID,
  onClick
}) => {
  const link =
    offer?.canonical ||
    offer?.url ||
    (offer?.id ? `/specialoffers/${offer?.id}` : null) ||
    '#';

  return (
    <SpecOfferItemWrapper key={offer?.id}>
      <OfferImageContainer>
        <Link href={link} passHref>
          <OfferImage>
            <Image
              src={offer?.banner_src ?? ''}
              layout="fill"
              objectFit="cover"
              alt="Изображение специального предложения"
              title={offer?.title}
            />
          </OfferImage>
        </Link>
      </OfferImageContainer>

      <OfferInfo>
        <Link href={link} passHref target="_blank">
          <Title>{offer?.title}</Title>
        </Link>
        <Description>{HtmlParser(offer?.description ?? '')}</Description>
      </OfferInfo>
      <GoToMoreWrapper>
        <GoToMore 
          href={link} 
          passHref 
          target="_blank"
          onClick={onClick}>
          {linkText}
        </GoToMore>
      </GoToMoreWrapper>
    </SpecOfferItemWrapper>
  );
};

export default SpecialOffersItem;
