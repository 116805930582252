import BlockTitle from '@components/atoms/BlockTitle';
import Button from '@components/atoms/Button';
import {theme} from '@styles/theme';
import styled from 'styled-components';

export const SuppliersWrapper = styled.div`
  margin-bottom: 80px;

  @media (max-width: 768px) {
    margin-bottom: 50px;
  }
`;

export const SuppliersTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

export const SuppliersItems = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 30px;
  justify-items: center;
  align-items: center;
  @media (max-width: 1050px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 768px) {
    margin-bottom: 30px;
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 520px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const LearnMoreItem = styled.div`
  opacity: 0;
  position: absolute;
  top: 0;
  background: ${({theme}) => theme.colors.brandGreenV2};
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  letter-spacing: 0.1px;
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
  cursor: pointer;
  transition: opacity 0.25s;
`;

export const SupplierItem = styled.div`
  position: relative;
  display: flex;
  max-height: 84px;
  max-width: 160px;
  border: 1px solid ${theme.colors.lightGrayV2};
  border-radius: 4px;
  &:hover ${LearnMoreItem} {
    opacity: 1;
  }
  @media (max-width: 1025px) {
    max-width: 210px;
  }
  @media (max-width: 480px) {
    max-width: 130px;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  line-height: 0;
  font-size: 0;
  text-align: center;
  padding: 0px 14px;
  max-height: 82px;
  min-height: 82px;
  display: flex;
  align-items: center;
`;

export const StyledTitle = styled(BlockTitle)`
  color: ${theme.colors.darkText};
  letter-spacing: '-0.25 px';

  @media (max-width: 768px) {
    font-size: 24px;
  }
  @media (max-width: 560px) {
    font-size: 20px;
  }
`;

export const StyledButton = styled(Button)`
  padding: 10px 18px;
  font-weight: 500;
`;

export const MobileButton = styled(StyledButton)`
  width: 100%;
  height: 40px;
  @media (min-width: 601px) {
    display: none;
  }
`;

export const DesktopButton = styled(StyledButton)`
  height: 40px;
  @media (max-width: 600px) {
    display: none;
  }
`;
