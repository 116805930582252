import RangeInput from '@components/atoms/RangeWrapper';
import {theme} from '@styles/theme';
import styled from 'styled-components';

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  flex: 1 1 50%;

  @media (max-width: 500px) {
    flex-direction: column;
    gap: 30px;
  }
`;


export const InputsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  @media (max-width: 768px) {
      gap: 15px;
  }
  @media (max-width: 500px) {
      grid-template-columns: 1fr;
      gap: 40px;
  }
`

export const RangeInputWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
`;

export const PriceRangeInputMinMax = styled.div`
  display: flex;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  bottom: -45%;
  width: 100%;

  p {
    font-size: 10px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 0.1px;
    color: ${theme.colors.gray};
  }
  @media (max-width: 800px) {
    width: 99%;
    margin: 4px 1.5px;
  }
`;



export const StyledRangeInput = styled(RangeInput)`
  display: flex;
  position: relative;
  min-height: 50px;
  max-height: 50px;
  label {
    text-transform: none;
    top: -7px;
    font-weight: 400;
    letter-spacing: 0.1px;
    background-color: ${theme.colors.lightBG};
    color: ${theme.colors.gray};
  }
  & > input[type='range'] {
    position: absolute;
    background-color: ${theme.colors.lightGrayV2};
    height: 1px;
    top: initial;
    bottom: -1px;
    pointer-events: all;
    transition: all 0.5s;
  }
`;


