import {theme} from '@styles/theme';
import styled from 'styled-components';

export const LeasingTermsWrapper = styled.div`
  background: ${theme.colors.lightBG};
`;

export const LeasingTermsTitle = styled.div`
  font-family: ${theme.fonts.headline};
  font-size: 36px;
  line-height: 44px;
  color: ${theme.colors.darkText};
  width: 100%;
  padding: 80px 0 50px 0;

  @media (max-width: 768px) {
    padding: 50px 0;
    font-size: 24px;
  }
  @media (max-width: 630px) {
    padding: 50px 0 30px 0;
    font-size: 20px;
  }
`;

export const LeasingTermsCards = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 80px;

  @media (max-width: 1100px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    padding-bottom: 50px;
  }
`;

export const LeasingTermsCard = styled.div`
  flex-basis: 165px;
  

  @media (max-width: 630px) {
    width: 100%;
    flex-basis: auto;
  }
`;

export const LeasingTermsCardImg = styled.div`
  width: 28px;
  margin-bottom: 8px;
  margin-right: 165px;

  @media (max-width: 1060px) {
    margin-right: 0px;
  }
`;

export const LeasingTermsCardTitle = styled.div`
  font-family: ${theme.fonts.headline};
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: ${theme.colors.gray};
  margin: 8px 0;
  max-width: 125px;

  @media (max-width: 630px) {
    max-width: 100%;
  }
`;

export const LeasingTermsCardText = styled.div`
  font-size: 18px;
  line-height: 24px;
  color: ${theme.colors.darkText};
`;

export const LeasingTermsCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 60px 0;
  @media (max-width: 1100px) {
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 50px;
    gap: 60px 30px;
  }

  @media (max-width: 630px) {
    flex-direction: column;
    gap: 32px;
  }
`;

export const ButtonWrapper = styled.div`
  @media (max-width: 630px) {
    width: 100%;
    button {
      width: 100%;
    }
  }
`;
