import {getBlogArticles} from '@api/blog';
import {getCatalogCategories} from '@api/header';
import {getPartnershipSupplierList} from '@api/providers';
import {AnalyticEventCategories, AnalyticEventNames} from '@api/types/AnalyticEvents';
import {IPageBannerData} from '@api/types/Banners';
import {IBlogArticlesGridCollectionItem} from '@api/types/Blog';
import React, {useEffect, useState, useRef} from 'react';
import {setCurrentPageTitle} from '@store/slices/pageInfo';
import {getMainPage, getPageBanners, getUTPByCode, getCalcInitData} from '@api/main';
import {CalcResult, IMainPageSlides, ISpecialOffers, IUTP} from '@api/types/MainPage';
import {MainPageCategory} from '@api/types/CategoriesT';
import {IPageMetaData} from '@api/types/Meta';
import {IPagePropertiesData} from '@api/types/Pages';
import {ISupplierData, ISupplierGridCollectionItem} from '@api/types/Providers';
import Banner from '@components/molecules/Banners/Banner';
import BannerSlider from '@components/molecules/Banners/BannerSlider';
import BlogBlockItem from '@components/molecules/BlogBlockItem';
import {SlideWrapper} from '@components/molecules/DotsForSlider.styles';
import MainPageCalcForm from '@components/molecules/MainPageCalc/MainPageCalcForm';
import LeasTermsAndCatalogPreview from '@components/organisms/MainPage/LeasTermsAndCatalogPreview';
import MainPageCalc from '@components/organisms/MainPage/MainPageCalc';
import OnlineApplicationBlock from '@components/organisms/MainPage/OnlineApplicationBlock';
import OnlineServicesBlock from '@components/organisms/MainPage/OnlineServicesBlock';
import SpecialOffers from '@components/organisms/MainPage/SpecialOffers';
import SuppliersBlock from '@components/organisms/MainPage/SuppliersBlock';
import Slider from '@components/organisms/Slider';
import {Config} from '@config/api';
import {useAppDispatch, useObserver} from '@hooks';
import useBannerSwitcher from '@hooks/useBannerSwitcher';
import {calcCompetitorsMock, calcSettingsMock} from '@mockData/calcResultMock';
import {MainPageWrapper} from '@styles/pages/index.styles';
import {promiseAllSettledProps, sendClientParamsNATS} from '@utils/common';
import {CalcResultContext} from '@utils/contexts';
import {getPageProperties} from '@utils/mapper';
import type {GetServerSidePropsContext, NextPage} from 'next';
import {GetServerSideProps} from 'next';
import Head from 'next/head';
import ym from 'react-yandex-metrika';
import MainCatalog from '@components/organisms/MainPage/MainCatalog';
import {NEW_HOMEPAGE} from '@constants/experimentsCodes';
import CalcAndSuppliers from '@components/organisms/MainPage/CalcAndSuppliers';
import {withExperiments} from '@utils/withExperiments';
import {EXPERIMENTS} from '@constants/paramNames';

interface MainPageProps {
  categories: MainPageCategory[];
  specialOffers: ISpecialOffers[];
  supplierItems: ISupplierGridCollectionItem[];
  slides: IMainPageSlides[];
  pageMeta: IPageMetaData | null;
  blogArticles: IBlogArticlesGridCollectionItem[];
  pageProperties: Partial<IPagePropertiesData> | null;
  firstPositionBanners: IPageBannerData[] | null;
  secondPositionBanners: IPageBannerData[] | null;
  thirdPositionBanners: IPageBannerData[] | null;
  pageId: number;
  utps: IUTP[];
  calcSettings: typeof calcSettingsMock;
  experiments: string[];
}

const MainPage: NextPage<MainPageProps> = ({
  categories,
  specialOffers,
  supplierItems,
  slides,
  pageProperties,
  blogArticles,
  firstPositionBanners,
  secondPositionBanners,
  thirdPositionBanners,
  pageId,
  utps,
  calcSettings,
  experiments,
}) => {
  const [calcResult, setCalcResult] = useState<CalcResult | null>(null);
  const dispatch = useAppDispatch();
  const pageWrapperRef = useRef<HTMLDivElement>(null);
  const catalogPreviewRef = useRef<HTMLDivElement>(null);
  const leasingTearmsRef = useRef<HTMLElement>(null);
  const calcRef = useRef<HTMLElement>(null);
  const compactCalcRef = useRef<HTMLElement>(null);
  const specialOfferRef = useRef<HTMLElement>(null);
  const onlineServicesRef = useRef<HTMLElement>(null);
  const suppliersRef = useRef<HTMLElement>(null);
  const blogRef = useRef<HTMLElement>(null);

  const firstBanner = useBannerSwitcher(firstPositionBanners, `${pageId}_1`);
  const secondBanner = useBannerSwitcher(secondPositionBanners, `${pageId}_2`);
  const thirdBanner = useBannerSwitcher(thirdPositionBanners, `${pageId}_3`);
  const isInfiniteCatalogView = experiments.includes(NEW_HOMEPAGE);

  useEffect(() => {
    dispatch(
      setCurrentPageTitle({
        currentPageTitle: 'РосАгроЛизинг',
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    sendClientParamsNATS(AnalyticEventNames.PageView, {
      eventName: AnalyticEventNames.MainPageView,
      eventCategory: AnalyticEventCategories.MainPage,
    });
  }, []);

  useObserver([leasingTearmsRef.current as Element], async () => {
    sendClientParamsNATS(AnalyticEventNames.MainPageShowPreferentialLeasing, {
      eventName: AnalyticEventNames.MainPageShowPreferentialLeasing,
      eventCategory: AnalyticEventCategories.MainPage,
    });
  });
  useObserver([catalogPreviewRef.current as Element], async () => {
    sendClientParamsNATS(AnalyticEventNames.MainPageShowCatalog, {
      eventName: AnalyticEventNames.MainPageShowCatalog,
      eventCategory: AnalyticEventCategories.MainPage,
    });
  });
  useObserver([calcRef.current as Element], async () => {
    sendClientParamsNATS(AnalyticEventNames.MainPageShowCalculator, {
      eventName: AnalyticEventNames.MainPageShowCalculator,
      eventCategory: AnalyticEventCategories.MainPage,
    });
  });

  useObserver([compactCalcRef.current as Element], async () => {
    sendClientParamsNATS(AnalyticEventNames.MainPageShowCalculator, {
      eventName: AnalyticEventNames.MainPageShowCalculator,
      eventCategory: AnalyticEventCategories.MainPage,
      eventParams: {
        type: 'small',
      },
    });
  });
  useObserver([specialOfferRef.current as Element], async () => {
    sendClientParamsNATS(AnalyticEventNames.MainPageShowSpecialOffer, {
      eventName: AnalyticEventNames.MainPageShowSpecialOffer,
      eventCategory: AnalyticEventCategories.MainPage,
    });
  });
  useObserver([onlineServicesRef.current as Element], async () => {
    sendClientParamsNATS(AnalyticEventNames.MainPageShowServices, {
      eventName: AnalyticEventNames.MainPageShowServices,
      eventCategory: AnalyticEventCategories.MainPage,
    });
  });
  useObserver([suppliersRef.current as Element], async () => {
    sendClientParamsNATS(AnalyticEventNames.MainPageShowSuppliers, {
      eventName: AnalyticEventNames.MainPageShowSuppliers,
      eventCategory: AnalyticEventCategories.MainPage,
    });
  });
  useObserver([blogRef.current as Element], async () => {
    sendClientParamsNATS(AnalyticEventNames.MainPageShowBlog, {
      eventName: AnalyticEventNames.MainPageShowBlog,
      eventCategory: AnalyticEventCategories.MainPage,
    });
  });

  return (
    <MainPageWrapper ref={pageWrapperRef}>
      <Head>
        <title>{pageProperties?.title}</title>
        <meta name="description" content={pageProperties?.description} />
        <link
          rel="canonical"
          href={`${Config.BASE_URL}${pageProperties?.canonical_url}`}
        />
      </Head>
      {slides?.length !== 0 && <BannerSlider slides={slides} experiments={experiments} />}
      <LeasTermsAndCatalogPreview
        nodeRefs={{
          catalogPreviewRef,
          leasingTearmsRef,
        }}
        categories={categories}
        utps={utps.filter((utp: IUTP) => Boolean(Number(utp.isactive)))}
        experiments={experiments}
      />
      {isInfiniteCatalogView && (
        <CalcAndSuppliers
          calcNodeRef={compactCalcRef}
          suppliersNodeRef={suppliersRef}
          calcSettings={calcSettings}
          supplierItems={supplierItems}
        />
      )}
      {!!firstBanner && !isInfiniteCatalogView && (
        <Banner
          title={firstBanner.title}
          text={firstBanner.banner_text}
          btnText={firstBanner.text_CTA_button}
          btnLink={firstBanner.url}
          imgUrl={firstBanner.image}
          bgColor="#FCF37B"
          imgWidth={45}
          centerImg
          withBtn={!!firstBanner.url}
          btnOnClick={() => {
            if (firstBanner.metrix_goal) {
              ym(
                firstBanner.metrix_goal.split(',')[1],
                firstBanner.metrix_goal.split(',')[2],
              );
            }
          }}
        />
      )}
      {!isInfiniteCatalogView && (
        <CalcResultContext.Provider value={[calcResult, setCalcResult]}>
          <MainPageCalc
            FormCalc={MainPageCalcForm}
            variantButton={4}
            calcSettings={calcSettings}
            relevantCompetitors={calcCompetitorsMock}
            nodeRef={calcRef}
            onLeaveOrderClick={() => {
              sendClientParamsNATS(AnalyticEventNames.MainPageClickLeaveApplication, {
                eventName: AnalyticEventNames.MainPageClickLeaveApplication,
                eventCategory: AnalyticEventCategories.MainPage,
                eventParams: {
                  block: 'calculator',
                },
              });
            }}
          />
        </CalcResultContext.Provider>
      )}
      {!isInfiniteCatalogView && (
        <SpecialOffers
          nodeRef={specialOfferRef}
          offers={specialOffers.length >= 4 ? specialOffers.slice(0, 4) : specialOffers}
        />
      )}
      {!!secondBanner && !isInfiniteCatalogView && (
        <Banner
          title={secondBanner.title}
          text={secondBanner.banner_text}
          btnText={secondBanner.text_CTA_button}
          btnLink={secondBanner.url}
          imgUrl={secondBanner.image}
          bgColor="#66AC9C"
          titleColor="white"
          textColor="rgba(255, 255, 255, 0.6)"
          centerImg
          withBtn={!!secondBanner.url}
          btnOnClick={() => {
            if (secondBanner.metrix_goal) {
              ym(
                secondBanner.metrix_goal.split(',')[1],
                secondBanner.metrix_goal.split(',')[2],
              );
            }
          }}
        />
      )}

      {!isInfiniteCatalogView && <OnlineServicesBlock nodeRef={onlineServicesRef} />}
      {!isInfiniteCatalogView && supplierItems && supplierItems.length !== 0 && (
        <SuppliersBlock nodeRef={suppliersRef} supplierItems={supplierItems} />
      )}
      {isInfiniteCatalogView && <MainCatalog />}
      {/* <Slider
        title={content.main.additional.title}
        btnTitleText="Все сервисы"
        titleRoute=""
        subTitle={content.main.additional.text}
        slidesLength={content.main.additional.blockItems.length}
        slidesPerView={2}
        type="AdditionalMainBlock"
        wrapperBG={theme.colors.lightBG}>
        {content.main.additional.blockItems.map((item, index) => (
          <AdditionalBlockSlideWrapper
            className="keen-slider__slide"
            key={index}
            itemsLength={content.main.additional.blockItems.length}>
            <AdditionalBlockItem
              title={item.title}
              text={item.text}
              img={item.img}
              url={'#'}
            />
          </AdditionalBlockSlideWrapper>
        ))}
      </Slider> */}

      {/* <AdditionalBlock /> */}
      {!!thirdBanner && (
        <Banner
          title={thirdBanner.title}
          text={thirdBanner.banner_text}
          btnText={thirdBanner.text_CTA_button}
          btnLink={thirdBanner.url}
          imgUrl={thirdBanner.image}
          bgColor="linear-gradient(270deg, #FCF37B 50%, #FCF37B 87.92%);"
          imgWidth={45}
          centerImg
          withBtn={!!thirdBanner.url}
          btnOnClick={() => {
            if (thirdBanner.metrix_goal) {
              ym(
                thirdBanner.metrix_goal.split(',')[1],
                thirdBanner.metrix_goal.split(',')[2],
              );
            }
          }}
        />
      )}
      {blogArticles && blogArticles.length !== 0 && (
        <Slider
          nodeRef={blogRef}
          title="Блог компании"
          btnTitleText="Все статьи"
          btnOnClick={() => {
            sendClientParamsNATS(AnalyticEventNames.MainPageClickAllArticles, {
              eventName: AnalyticEventNames.MainPageClickAllArticles,
              eventCategory: AnalyticEventCategories.MainPage,
            });
          }}
          titleRoute="/company/blog/articles/"
          slidesLength={blogArticles.length}
          slidesPerView={4}>
          {blogArticles
            .filter(article => article?.id && article?.id !== null)
            .map((article, index) => (
              <SlideWrapper className="keen-slider__slide" key={index}>
                <BlogBlockItem
                  title={article?.title ?? ''}
                  url={
                    article?.detail_url && article.detail_url.length !== 0
                      ? article.detail_url
                      : `/company/blog/articles/${article?.id}/`
                  }
                  img={article?.image ?? ''}
                  onClick={() => {
                    sendClientParamsNATS(AnalyticEventNames.MainPageClickArticle, {
                      eventName: AnalyticEventNames.MainPageClickArticle,
                      eventCategory: AnalyticEventCategories.MainPage,
                    });
                  }}
                />
              </SlideWrapper>
            ))}
        </Slider>
      )}
      {!isInfiniteCatalogView && (
        <OnlineApplicationBlock title="Заявка на обратный звонок" />
      )}
    </MainPageWrapper>
  );
};

export default MainPage;

export const getServerSideProps: GetServerSideProps = withExperiments(
  async (ctx: GetServerSidePropsContext, apiClient) => {
    const mainPagePromise = getMainPage();
    const pagePropertiesPromise = getPageProperties(ctx.resolvedUrl.split('?')[0]);
    const suppliersListPromise: Promise<ISupplierData> = getPartnershipSupplierList(
      {page: 1, nPaginator: 1, items_per_page: 12},
      {},
    );
    const blogArticlesPromise = getBlogArticles(
      {
        items_per_page: 4,
        page: 1,
        nPaginator: 1,
      },
      {},
    );
    const PAGE_ID = 19;
    const resPromise = getCatalogCategories();
    const pageBannersPromise = getPageBanners(PAGE_ID);

    // const result = await Promise
    //   .allSettled([
    //     mainPagePromise,
    //     pagePropertiesPromise,
    //     suppliersListPromise,
    //     blogArticlesPromise,
    //     resPromise,
    //     pageBannersPromise
    //   ]);
    //
    // const [
    //   mainPage,
    //   pageProperties,
    //   suppliersList,
    //   blogArticles,
    //   res,
    //   pageBanners
    // ] = result.map(el => el.status === 'fulfilled' ? el.value : null);

    const utpPromise = getUTPByCode();
    const calcSettingsPromise = getCalcInitData({isshtp: true});

    const [
      mainPage,
      pageProperties,
      suppliersList,
      blogArticles,
      res,
      pageBanners,
      utp,
      calcSettings,
    ] = await promiseAllSettledProps([
      mainPagePromise,
      pagePropertiesPromise,
      suppliersListPromise,
      blogArticlesPromise,
      resPromise,
      pageBannersPromise,
      utpPromise,
      calcSettingsPromise,
    ]);

    const categories = mainPage?.categories.map((item: MainPageCategory) => {
      const category = res.categories.find(
        (e: {id: string | undefined}) => e.id === item.id_category,
      );
      item.uri = category.uri;
      return item;
    });

    function getBanner(id: string) {
      const banner = pageBanners?.pageBanners?.filter((item: IPageBannerData) => {
        return item.position === id;
      });
      return banner ? banner : null;
    }
    const firstPositionBanners = getBanner('1');
    const secondPositionBanners = getBanner('2');
    const thirdPositionBanners = getBanner('3');
    const experiments = ctx?.req?.cookies?.[EXPERIMENTS] ?? [];
    return {
      props: {
        categories: categories || [],
        specialOffers: mainPage?.offers || [],
        supplierItems: suppliersList?.Grid.items.GridCollection.items || [],
        slides: mainPage?.slides || [],
        pageProperties: pageProperties,
        blogArticles: blogArticles?.articles?.Grid?.items?.GridCollection?.items || [],
        firstPositionBanners,
        secondPositionBanners,
        thirdPositionBanners,
        pageId: PAGE_ID,
        utps: utp?.utps?.items || [],
        calcSettings: calcSettings || {},
        experiments,
      },
    };
  },
);
