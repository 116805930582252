import {IMainPageSlides} from '@api/types/MainPage';
import {useWindowDimensions} from '@hooks';
import {GlobalContainer} from '@styles/pages/_app.styles';
import {KeenSliderInstance, KeenSliderOptions} from 'keen-slider';
import {useKeenSlider} from 'keen-slider/react';
import {FC, useEffect, useState} from 'react';
import ControlsForSlider from '../ControlsForSlider';
import {
  AllSpecialOffers,
  CenterWrapper,
  SliderWrapper,
  SlideWrapper,
} from './BannerSlider.styles';
import SaleBanner from './SaleBanner';
import {trackEvent} from '@utils/analytics';
import {AnalyticEventCategories, AnalyticEventNames} from '@api/types/AnalyticEvents';
import {NEW_HOMEPAGE} from '@constants/experimentsCodes';

const AutoplayPlugin = (slider: KeenSliderInstance) => {
  if (slider.slides.length < 2) {
    return;
  }
  let timeout: ReturnType<typeof setTimeout>;
  let mouseOver = false;
  function clearNextTimeout() {
    clearTimeout(timeout);
  }
  function nextTimeout() {
    clearTimeout(timeout);
    if (mouseOver) return;
    timeout = setTimeout(() => {
      slider.next();
    }, 4000);
  }
  slider.on('created', () => {
    slider.container.addEventListener('mouseover', () => {
      mouseOver = true;
      clearNextTimeout();
    });
    slider.container.addEventListener('mouseout', () => {
      mouseOver = false;
      nextTimeout();
    });
    nextTimeout();
  });
  slider.on('dragStarted', clearNextTimeout);
  slider.on('animationEnded', nextTimeout);
  slider.on('updated', nextTimeout);
};

interface BannerSliderProps {
  slides: IMainPageSlides[];
  experiments?: string[];
}

const BannerSlider: FC<BannerSliderProps> = ({slides, experiments}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const {width} = useWindowDimensions();
  const isInfiniteCatalogView = experiments?.includes(NEW_HOMEPAGE);
  const sliderOptions: KeenSliderOptions = {
    initial: 0,
    loop: true,
    dragSpeed: 1.5,
    renderMode: 'performance',
    slideChanged(slider: KeenSliderInstance) {
      setCurrentSlide(slider.track.details.rel);
    },
    slides: {
      perView: isInfiniteCatalogView && slides.length > 1 ? 2 : 1,
      origin: 'auto',
    },
    breakpoints: {
      '(max-width: 768px)': {
        slides: {
          perView: 1,
        },
      },
    },
  };

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(sliderOptions, [
    AutoplayPlugin,
  ]);

  useEffect(() => {
    if (instanceRef.current) {
      setLoaded(true);
    }
  }, [instanceRef]);

  return (
    <SliderWrapper ref={sliderRef} className="keen-slider" loaded={loaded}>
      {slides.map((banner, idx) => (
        <SlideWrapper className={`keen-slider__slide`} key={idx}>
          <SaleBanner
            title={banner.title}
            text={typeof banner.text === 'string' ? banner.text : ''}
            params={banner.params}
            isTwoSlidesView={isInfiniteCatalogView && slides.length > 1}
            imgSrc={banner.backgroundPhoto?.src ?? ''}
            btnText={banner.button_text}
            btnLink={
              banner.url
                ? banner.url
                : banner.category
                ? `/catalog/${
                    banner.category.uri ? banner.category.uri : banner.category.id
                  }` // TODO нужно с бэка для баннеров которые ссылаются на категорию возвращать новое поле uri или присылать uri в поле id, тогда будет работать с ЧПУ
                : ''
            }
          />
        </SlideWrapper>
      ))}
      {loaded && instanceRef.current && slides.length > 1 && (
        <CenterWrapper $isTwoSlidesView={!!(isInfiniteCatalogView && slides.length > 1)}>
          <GlobalContainer style={{position: 'relative'}}>
            <ControlsForSlider
              onClickLeft={() => {
                if (typeof instanceRef.current?.track?.details?.rel !== 'undefined') {
                  trackEvent(
                    AnalyticEventCategories.MainPage,
                    AnalyticEventNames.MainPageBannerArrowClicked,
                    {
                      banner_name: slides[instanceRef.current.track.details.rel].title,
                      type: 'left',
                    },
                  );
                }
                instanceRef.current?.prev();
              }}
              onClickRight={() => {
                if (typeof instanceRef.current?.track?.details?.rel !== 'undefined') {
                  trackEvent(
                    AnalyticEventCategories.MainPage,
                    AnalyticEventNames.MainPageBannerArrowClicked,
                    {
                      banner_name: slides[instanceRef.current.track.details.rel].title,
                      type: 'right',
                    },
                  );
                }
                instanceRef.current?.next();
              }}
              currentSlide={currentSlide + 1}
              allSlides={slides.length}
              showSlideNumber={
                width! <= 500 || (isInfiniteCatalogView && slides.length > 1)
                  ? false
                  : true
              }
              style={
                isInfiniteCatalogView && slides.length > 1 && width! >= 769
                  ? {justifyContent: 'center'}
                  : {}
              }
            />
            <AllSpecialOffers href="/specialoffers/">
              Все спецпредложения
            </AllSpecialOffers>
          </GlobalContainer>
        </CenterWrapper>
      )}
    </SliderWrapper>
  );
};

export default BannerSlider;
