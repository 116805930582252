import {FC} from 'react';
import {CurrentSliderNumberWrapper} from './CurrentSliderNumber.styles';

interface CurrentSliderNumberProps {
  currentSlide: number;
  allSlides: number;
}

const CurrentSliderNumber: FC<CurrentSliderNumberProps> = ({currentSlide, allSlides}) => {
  return (
    <CurrentSliderNumberWrapper>
      {currentSlide}/{allSlides}
    </CurrentSliderNumberWrapper>
  );
};

export default CurrentSliderNumber;
