import ArrowButton from '@components/atoms/ArrowButton';
import CurrentSliderNumber from '@components/atoms/CurrentSliderNumber';
import {FC} from 'react';
import {ControlsWrapper} from './ControlsForSlider.styles';

interface ControlsForSliderProps {
  onClickLeft: (e: any) => any | (() => void) | undefined;
  onClickRight: (e: any) => any | (() => void) | undefined;
  currentSlide: number;
  allSlides: number;
  showSlideNumber?: boolean;
  style?: Record<string, string>;
}

const ControlsForSlider: FC<ControlsForSliderProps> = ({
  onClickLeft,
  onClickRight,
  currentSlide,
  allSlides,
  showSlideNumber = true,
  style,
}) => {
  return (
    <ControlsWrapper style={style}>
      <ArrowButton type="left" onClick={onClickLeft} />
      <ArrowButton type="right" onClick={onClickRight} />
      {showSlideNumber && (
        <CurrentSliderNumber currentSlide={currentSlide} allSlides={allSlides} />
      )}
    </ControlsWrapper>
  );
};

export default ControlsForSlider;
