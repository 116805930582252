import {theme} from '@styles/theme';
import Link from 'next/link';
import styled from 'styled-components';

export const GoToMore = styled(Link)`
  color: ${({theme}) => theme.colors.brandGreenV2};
`;

export const GoToMoreWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  a {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: ${theme.colors.brandGreenV2};
    &:after {
      content: url('/assets/icons/rightArrowIcon.svg');
      ${theme.filters.brandGreenV2}
      margin-left: 8px;
      padding-top: 4px;
    }
  }
`;

export const SpecOfferItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  cursor: pointer;
  &:hover ${GoToMoreWrapper} a {
    color: ${({theme}) => theme.colors.lightGreen};
    &:after {
      content: url('/assets/icons/rightArrowIcon.svg');
      ${theme.filters.lightGreen}
    }
  }
`;

export const OfferInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OfferImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  a {
    width: 100%;
  }
`;

export const OfferImage = styled.div`
  img {
    border-radius: 4px;
  }
  position: relative;
  width: 100%;
  height: 208px;
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: ${theme.colors.darkText};
  margin-top: 20px;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @media (max-width: 820px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: ${theme.colors.gray};
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 4px;
`;
