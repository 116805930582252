import {ISupplierGridCollectionItem} from '@api/types/Providers';
import {GlobalContainer} from '@styles/pages/_app.styles';
import Image from 'next/legacy/image';
import Link from 'next/link';
import {FC, useEffect, useRef, useState} from 'react';
import {AnalyticEventCategories, AnalyticEventNames} from '@api/types/AnalyticEvents';
import {sendClientParamsNATS} from '@utils/common';
import {
  CalcAndSuppliersGrid,
  ImageWrapper,
  LearnMoreItem,
  ShowMoreButton,
  StyledTitle,
  SupplierItem,
  SuppliersItems,
  CalcAndSuppliersWrapper,
  SuppliersWrapper,
  CalcWrapper,
  CalcModalCloseBtn,
} from './CalcAndSuppliers.styles';
import {CalcSettings} from '@api/types/CatalogProduct';
import {CalcResultContext} from '@utils/contexts';
import MainPageCalc from './MainPageCalc';
import MainPageCalcForm from '@components/molecules/MainPageCalc/MainPageCalcForm';
import {calcCompetitorsMock} from '@mockData/calcResultMock';
import {CalcResult} from '@api/types/MainPage';
import MainPageCompactCalc from './MainPageCompactCalc';
import MainPageCompactCalcForm from '@components/molecules/MainPageCompactCalcForm/MainPageCompactCalcForm';
import ModalWindow from '@components/molecules/ModalWindow';

interface CalcAndSuppliersProps {
  supplierItems: ISupplierGridCollectionItem[];
  calcSettings: CalcSettings;
  calcNodeRef: React.MutableRefObject<any>;
  suppliersNodeRef: React.MutableRefObject<any>;
}

const CalcAndSuppliers: FC<CalcAndSuppliersProps> = ({
  supplierItems,
  calcSettings,
  calcNodeRef,
  suppliersNodeRef
}) => {
  const link = '/providers/catalog';
  const [calcResult, setCalcResult] = useState<CalcResult | null>(null);
  const [modalCalcOpened, setModalCalcOpened] = useState(false);
  const calcRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (modalCalcOpened) {
      sendClientParamsNATS(AnalyticEventNames.MainPageShowCalculator, {
        eventName: AnalyticEventNames.MainPageShowCalculator,
        eventCategory: AnalyticEventCategories.MainPage,
      });
    }
  }, [modalCalcOpened]);
  return (
    <CalcAndSuppliersWrapper>
      <GlobalContainer>
        <CalcAndSuppliersGrid>
          <CalcWrapper ref={calcNodeRef}>
            <StyledTitle>Калькулятор</StyledTitle>
            <CalcResultContext.Provider value={[calcResult, setCalcResult]}>
              <MainPageCompactCalc
                FormCalc={MainPageCompactCalcForm}
                calcSettings={calcSettings}
                relevantCompetitors={calcCompetitorsMock}
              />
              <ShowMoreButton
                title="Рассчитать"
                variant="filled"
                onClick={() => {
                  setModalCalcOpened(true);
                  sendClientParamsNATS(AnalyticEventNames.MainPageCompactCalcBtnClicked, {
                    eventName: AnalyticEventNames.MainPageCompactCalcBtnClicked,
                    eventCategory: AnalyticEventCategories.MainPage,
                    eventParams: {
                      type: 'small',
                    },
                  });
                }}
              />
            </CalcResultContext.Provider>
          </CalcWrapper>
          <SuppliersWrapper ref={suppliersNodeRef}>
            <StyledTitle>Поставщики</StyledTitle>
            <SuppliersItems>
              {supplierItems.slice(0, 6).map((item, i) => (
                <Link
                  href={`/providers/${item.id}`}
                  key={i}
                  passHref
                  onClick={() => {
                    sendClientParamsNATS(AnalyticEventNames.MainPageClickSupplier, {
                      eventName: AnalyticEventNames.MainPageClickSupplier,
                      eventCategory: AnalyticEventCategories.MainPage,
                    });
                  }}>
                  <SupplierItem key={i}>
                    <ImageWrapper>
                      <Image
                        src={`${item.logo}`}
                        layout="intrinsic"
                        width={200}
                        height={64}
                        objectFit="contain"
                        alt="Логотип поставщика"
                        title={item.title}
                      />
                    </ImageWrapper>
                    <LearnMoreItem>Узнать больше</LearnMoreItem>
                  </SupplierItem>
                </Link>
              ))}
            </SuppliersItems>
            <ShowMoreButton
              title="Все поставщики"
              variant="outlined"
              link={link}
              onClick={() => {
                sendClientParamsNATS(AnalyticEventNames.MainPageClickAllSuppliers, {
                  eventName: AnalyticEventNames.MainPageClickAllSuppliers,
                  eventCategory: AnalyticEventCategories.MainPage,
                });
              }}
            />
          </SuppliersWrapper>
        </CalcAndSuppliersGrid>
      </GlobalContainer>
      <ModalWindow isOpen={modalCalcOpened}>
        <CalcModalCloseBtn onClick={() => setModalCalcOpened(false)}>
          <Image
            src={'/assets/icons/closeBtn.svg'}
            layout="intrinsic"
            width={21}
            height={21}
            alt="Закрыть"
          />
        </CalcModalCloseBtn>
        <CalcResultContext.Provider value={[calcResult, setCalcResult]}>
          <MainPageCalc
            FormCalc={MainPageCalcForm}
            variantButton={4}
            calcSettings={calcSettings}
            relevantCompetitors={calcCompetitorsMock}
            nodeRef={calcRef}
            background="white"
            onLeaveOrderClick={(e) => {
              e.stopPropagation();
              window && window.open('/client/services/callback/', '_blank');
              setModalCalcOpened(false);
              sendClientParamsNATS(AnalyticEventNames.MainPageClickLeaveApplication, {
                eventName: AnalyticEventNames.MainPageClickLeaveApplication,
                eventCategory: AnalyticEventCategories.MainPage,
                eventParams: {
                  block: 'calculator',
                },
              });
            }}
          />
        </CalcResultContext.Provider>
      </ModalWindow>
    </CalcAndSuppliersWrapper>
  );
};

export default CalcAndSuppliers;
