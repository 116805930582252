import Image from 'next/legacy/image';
import Link from 'next/link';
import {FC} from 'react';
import {
  Arrow,
  ClientServiceCardText,
  ClientServiceCardWrapper,
  ImageWrapper,
  OnlineServicesTextWrapper,
} from './ClientServiceCard.styles';

interface ClientServiceCardProps {
  text: string;
  imgUrl: string;
  url: string;
  onClick?: () => void;
}

const ClientServiceCard: FC<ClientServiceCardProps> = ({text, imgUrl, url, onClick}) => {
  return (
    <Link 
      href={url} 
      passHref
      onClick={onClick}>
      <ClientServiceCardWrapper>
        <ImageWrapper>
          <Image
            src={imgUrl}
            layout="intrinsic"
            width={34}
            height={34}
            alt="Изображение сервиса"
          />
        </ImageWrapper>
        <OnlineServicesTextWrapper>
          <ClientServiceCardText>
            <span>{text}</span>{' '}
            <Arrow>
              <Image
                src={'/assets/icons/rightArrowIcon.svg'}
                layout="intrinsic"
                width={12}
                height={12}
                objectFit="contain"
                alt="arrow"
              />
            </Arrow>
          </ClientServiceCardText>
        </OnlineServicesTextWrapper>
      </ClientServiceCardWrapper>
    </Link>
  );
};

export default ClientServiceCard;
