import {ClipLoader} from 'react-spinners';
import {LoaderWrapper} from './Loader.styles';
import {FC} from 'react';

interface Props {
  className?: string;
}

const Loader: FC<Props> = ({className}) => {
  return (
    <LoaderWrapper className={className}>
      <ClipLoader color="#00755A" />
    </LoaderWrapper>
  );
};

export default Loader;
