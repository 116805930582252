import {ISupplierGridCollectionItem} from '@api/types/Providers';
import {GlobalContainer} from '@styles/pages/_app.styles';
import Image from 'next/legacy/image';
import Link from 'next/link';
import {FC} from 'react';
import {AnalyticEventCategories, AnalyticEventNames} from '@api/types/AnalyticEvents';
import { sendClientParamsNATS } from '@utils/common';
import {
  DesktopButton,
  ImageWrapper,
  LearnMoreItem,
  MobileButton,
  StyledTitle,
  SupplierItem,
  SuppliersItems,
  SuppliersTitleWrapper,
  SuppliersWrapper,
} from './SuppliersBlock.styles';

interface SuppliersBlockProps {
  supplierItems: ISupplierGridCollectionItem[];
  nodeRef?: React.MutableRefObject<any>;
}

const SuppliersBlock: FC<SuppliersBlockProps> = ({supplierItems, nodeRef}) => {
  const link = '/providers/catalog';

  return (
    <SuppliersWrapper ref={nodeRef}>
      <GlobalContainer>
        <SuppliersTitleWrapper>
          <StyledTitle>Поставщики</StyledTitle>
          <DesktopButton title="Все поставщики" variant="outlined" link={link} />
        </SuppliersTitleWrapper>
        <SuppliersItems>
          {supplierItems.map((item, i) => (
            <Link 
              href={`/providers/${item.id}`} 
              key={i} 
              passHref
              onClick={() => {
                sendClientParamsNATS(AnalyticEventNames.MainPageClickSupplier, {
                  eventName: AnalyticEventNames.MainPageClickSupplier,
                  eventCategory: AnalyticEventCategories.MainPage,
                })
              }}
              >
              <SupplierItem key={i}>
                <ImageWrapper>
                  <Image
                    src={`${item.logo}`}
                    layout="intrinsic"
                    width={200}
                    height={64}
                    objectFit="contain"
                    alt="Логотип поставщика"
                    title={item.title}
                  />
                </ImageWrapper>
                <LearnMoreItem>Узнать больше</LearnMoreItem>
              </SupplierItem>
            </Link>
          ))}
        </SuppliersItems>
        <MobileButton 
          title="Все поставщики" 
          variant="outlined" 
          link={link}
          onClick={() => {
            sendClientParamsNATS(AnalyticEventNames.MainPageClickAllSuppliers, {
              eventName: AnalyticEventNames.MainPageClickAllSuppliers,
              eventCategory: AnalyticEventCategories.MainPage,
            })
          }} />
      </GlobalContainer>
    </SuppliersWrapper>
  );
};

export default SuppliersBlock;
