import React, {FC, RefObject, useContext, useEffect, useState} from 'react';

import {getCalcListCategory} from '@api/main';
import {
  CalcSettings,
  CatalogProductCalcResult,
  Competitor,
} from '@api/types/CatalogProduct';
import {ICalcSettingsState} from '@components/molecules/ProductCard/ProductCardCalc';
import {CalcResultContext, RegionSelectedContext} from '@utils/contexts';
import {FormikProps} from 'formik';
import {
  MainPageCalcContainer,
  MainPageCalcWrapper,
  MainPageCalcResultTitle,
  MainPageCalcResultText,
  SkeletonWrapper,
  MainPageCalcTitleText,
} from './MainPageCompactCalc.styles';
import Skeleton from 'react-loading-skeleton';
import {calcResultMapper, divideNumber} from '@utils/formatters';
import {CalcResult} from '@api/types/MainPage';

interface MainPageCompactCalcProps {
  calcSettings: CalcSettings;
  relevantCompetitors: Competitor[];
  FormCalc: React.FC<{
    setCalcResult?: (values: CatalogProductCalcResult) => void;
    setCalcResultError?: (error: string) => void;
    setCalcResultLoading?: (values: boolean) => void;
    calcSettings: CalcSettings;
    relevantCompetitors: Competitor[];
    categories: Partial<Array<{title: string; value: string}>> | any[];
    activeTab: string;
    formikRef?: RefObject<FormikProps<ICalcSettingsState>>;
  }>;
  formikRef?: RefObject<FormikProps<ICalcSettingsState>>;
}

const MainPageCompactCalc: FC<MainPageCompactCalcProps> = ({
  calcSettings,
  relevantCompetitors,
  FormCalc,
  formikRef,
}) => {
  const [calcResult, setCalcResult] = useContext(CalcResultContext);
  const [calcResultLoading, setCalcResultLoading] = useState<boolean>(false);
  const [calcResultError, setCalcResultError] = useState('');
  const [activeTab, setActiveTab] = useState('optimal');
  const [regionSelected, setRegionSelected] = useState(false);
  const [mappedCalcResult, setMappedCalcResult] = useState<Partial<CalcResult>>();

  const [calcCategories, setCalcCategories] = useState<
    Partial<Array<{title: string; value: string}>>
  >([]);

  useEffect(() => {
    const getCategories = async () => {
      const categories = await getCalcListCategory();
      const formatedCategories: Array<{title: string; value: string}> =
        categories.items.map((item: {title: string; guid: string}) => {
          return {title: item.title, value: item.guid};
        });
      setCalcCategories(formatedCategories);
    };
    getCategories();
  }, []);

  useEffect(() => {
    if (calcResult) {
      setMappedCalcResult(calcResultMapper(calcResult));
    }
  }, [calcResult]);

  return (
    <MainPageCalcWrapper>
      <MainPageCalcContainer>
        <MainPageCalcTitleText>
          Сделайте предварительный расчёт ежемесячного платежа{' '}
        </MainPageCalcTitleText>
        <RegionSelectedContext.Provider value={[regionSelected, setRegionSelected]}>
          <FormCalc
            setCalcResultLoading={setCalcResultLoading}
            calcSettings={calcSettings}
            relevantCompetitors={relevantCompetitors}
            categories={calcCategories}
            activeTab={activeTab}
            formikRef={formikRef}
            setCalcResult={setCalcResult}
            setCalcResultError={setCalcResultError}
          />
        </RegionSelectedContext.Provider>

        <MainPageCalcResultTitle>
          <h3> ЕЖЕМЕСЯЧНЫЙ ПЛАТЁЖ ОТ </h3>
          <MainPageCalcResultText>
            {mappedCalcResult?.each_payment && !calcResultLoading ? (
              `${divideNumber(Math.floor(mappedCalcResult.each_payment!))} ₽`
            ) : (
              <SkeletonWrapper>
                <Skeleton />
              </SkeletonWrapper>
            )}
          </MainPageCalcResultText>
        </MainPageCalcResultTitle>
      </MainPageCalcContainer>
    </MainPageCalcWrapper>
  );
};

export default MainPageCompactCalc;
