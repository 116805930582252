export const servicesCardData = [
  {
    title: 'Электронный документооборот',
    src: '/assets/Services/ServicesIconsCard/servicesIconDocumentflow.svg',
    href: '/documentflow',
  },
  {
    title: 'Калькулятор лизинга',
    src: '/assets/Services/ServicesIconsCard/servicesIconCalc.svg',
    href: '/calculator',
  },
  // {
  //   title: 'Проверить статус письма онлайн',
  //   src: '/assets/Services/ServicesIconsCard/servicesIconCheckmail.svg',
  //   href: '/client/services/checkmail',
  // },
  {
    title: 'Оставить отзыв',
    src: '/assets/Services/ServicesIconsCard/servicesIconFeedback.svg',
    href: '/client/services/feedback',
  },
  {
    title: 'Мобильное приложение',
    src: '/assets/Services/ServicesIconsCard/servicesIconApp.svg',
    href: '/client/services/app',
  },
  // {
  //   title: 'Проверить штрафы ГИБДД',
  //   src: '/assets/Services/ServicesIconsCard/servicesIconFines.svg',
  //   href: '/client/services/fines',
  // },
  {
    title: 'Заказать обратный звонок',
    src: '/assets/Services/ServicesIconsCard/servicesIconCallback.svg',
    href: '/client/services/callback',
  },
];
