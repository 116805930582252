import {GlobalContainer} from '@styles/pages/_app.styles';
import React, {FC, useEffect, useRef, useState} from 'react';
import {
  MainCatalogItems,
  MainCatalogWrapper,
  MainCatalogTitleWrapper,
  StyledTitle,
  DesktopButton,
  MobileButton,
  MainCatalogLoader,
  MainCatalogBoundary,
  MainCatalogLoadMoreWrapper,
} from './MainCatalog.styles';
import {PromoProductDetails} from '@api/types/MainPage';
import Loader from '@components/atoms/Loader';
import {getCatalogPromo} from '@api/main';
import CatalogVerticalItem from '@components/molecules/Catalog/CatalogVerticalItem';
import {limitStr} from '@utils/formatters';
import {useObserver} from '@hooks';
import Button from '@components/atoms/Button';
import {trackEvent} from '@utils/analytics';
import {AnalyticEventCategories, AnalyticEventNames} from '@api/types/AnalyticEvents';

const MainCatalog: FC = () => {
  const [products, setProducts] = useState<PromoProductDetails[]>([]);
  const [productsLoading, setProductsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingCount, setLoadingCount] = useState(0);
  const [showMoreVisibe, setShowMoreVisible] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const boundaryRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const getProducts = async (perPage?: number) => {
    if (productsLoading) return;
    setProductsLoading(true);
    try {
      const res = await getCatalogPromo({page: currentPage, items_per_page: perPage});
      if (res.success && res.items) {
        setProducts([...products, ...res.items]);
        const nextPage = perPage === 40 && currentPage === 2 ? 5 : currentPage + 1;
        setCurrentPage(nextPage);
        setLoadingCount(loadingCount + 1);
        loadingCount &&
          trackEvent(
            AnalyticEventCategories.MainPage,
            AnalyticEventNames.MainPageEndlessFeedShown,
            {
              page: loadingCount + 1,
            },
          );
        setIsLastPage(currentPage === res.pages);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setProductsLoading(false);
    }
  };

  useEffect(() => {
    getProducts(40);
  }, []);

  useEffect(() => {
    setShowMoreVisible(currentPage === 5);
  }, [currentPage]);

  useObserver([boundaryRef.current as Element], async () => {
    !showMoreVisibe && !isLastPage && getProducts(currentPage >= 5 ? 20 : 40);
  });

  useObserver([wrapperRef.current as Element], async () => {
    trackEvent(
      AnalyticEventCategories.MainPage,
      AnalyticEventNames.MainPageEndlessFeedShown,
      {
        page: 1,
      },
    );
  });

  const onCatalogLinkClick = () => {
    trackEvent(
      AnalyticEventCategories.MainPage,
      AnalyticEventNames.MainPageClickGoToCatalog,
      {
        block: 'endless_feed',
      },
    );
  };

  return (
    <MainCatalogWrapper>
      <GlobalContainer>
        <MainCatalogTitleWrapper ref={wrapperRef}>
          <StyledTitle>Каталог</StyledTitle>
          <DesktopButton
            title="Перейти в каталог"
            variant="outlined"
            link={'/catalog'}
            onClick={onCatalogLinkClick}
          />
        </MainCatalogTitleWrapper>
        <MainCatalogItems>
          {!!products.length &&
            products.map((product, idx) => (
              <div
                key={idx}
                onClick={() => {
                  trackEvent(
                    AnalyticEventCategories.MainPage,
                    AnalyticEventNames.MainPageEndlessFeedItemClicked,
                  );
                }}>
                <CatalogVerticalItem
                  title={limitStr(product?.product?.title, 50)}
                  text={product?.manufacturer?.title}
                  id={String(product?.product?.id)}
                  img={product.photo.src || ''}
                  cost={product?.product?.price}
                  prefixFrom={true}
                  showFavBtn={true}
                />
              </div>
            ))}
          {productsLoading && (
            <MainCatalogLoader>
              <Loader />
            </MainCatalogLoader>
          )}
          <MainCatalogBoundary ref={boundaryRef} />
        </MainCatalogItems>
        {!productsLoading && showMoreVisibe && (
          <MainCatalogLoadMoreWrapper>
            <Button
              variant="filled"
              title="Продолжить"
              onClick={() => {
                trackEvent(
                  AnalyticEventCategories.MainPage,
                  AnalyticEventNames.MainPageEndlessFeedShowMoreClicked,
                );
                getProducts();
              }}
            />
          </MainCatalogLoadMoreWrapper>
        )}
        <MobileButton
          title="Перейти в каталог"
          variant="outlined"
          link={'/catalog'}
          onClick={onCatalogLinkClick}
        />
      </GlobalContainer>
    </MainCatalogWrapper>
  );
};

export default MainCatalog;
