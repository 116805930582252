import {ISpecialOffers} from '@api/types/MainPage';
import SpecialOffersItem from '@components/molecules/SpecialOffers/SpecialOffersItem';
import {GlobalContainer} from '@styles/pages/_app.styles';
import {isNull, isUndefined} from 'lodash';
import {AnalyticEventCategories, AnalyticEventNames} from '@api/types/AnalyticEvents';
import { sendClientParamsNATS } from '@utils/common';
import {FC} from 'react';
import {
  DesktopButton,
  MobileButton,
  SpecialOffersItems,
  SpecialOffersText,
  SpecialOffersTitle,
  SpecialOffersTitleWrapper,
  SpecialOffersWrapper,
} from './SpecialOffers.styles';

interface SpecialOffersProps {
  offers: ISpecialOffers[];
  nodeRef?: React.MutableRefObject<any>;
}

const SpecialOffers: FC<SpecialOffersProps> = ({offers, nodeRef}) => {
  return (
    <SpecialOffersWrapper ref={nodeRef}>
      <GlobalContainer>
        <SpecialOffersTitleWrapper>
          <SpecialOffersTitle>Специальные предложения</SpecialOffersTitle>
          <DesktopButton
            title="Все спецпредложения"
            variant="outlined"
            link={'/specialoffers'}
            onClick={() => {
              sendClientParamsNATS(AnalyticEventNames.MainPageClickAllSpecialOffer, {
                eventName: AnalyticEventNames.MainPageClickAllSpecialOffer,
                eventCategory: AnalyticEventCategories.MainPage,
              })
            }}
          />
        </SpecialOffersTitleWrapper>
        <SpecialOffersText>
          Возьмите технику в лизинг уже сегодня на специальных условиях
        </SpecialOffersText>
        <SpecialOffersItems>
          {offers?.map((offer, i) => {
            return (
              <SpecialOffersItem
                offer={offer}
                style={{display: i < 4 ? 'flex' : 'none'}}
                key={offer.id}
                haveID={!isUndefined(offer.id) && !isNull(offer.id)}
                onClick={() => {
                  sendClientParamsNATS(AnalyticEventNames.MainPageClickSpecialOffer, {
                    eventName: AnalyticEventNames.MainPageClickSpecialOffer,
                    eventCategory: AnalyticEventCategories.MainPage,
                  })
                }}
              />
            );
          })}
          <MobileButton
            title="Все спецпредложения"
            variant="outlined"
            link={'/specialoffers'}
          />
        </SpecialOffersItems>
      </GlobalContainer>
    </SpecialOffersWrapper>
  );
};

export default SpecialOffers;
