import {theme} from '@styles/theme';
import styled from 'styled-components';

export const CatalogPreviewItemCount = styled.div<{active: boolean | undefined}>`
  position: absolute;
  font-weight: 500;
  font-size: 13px;
  right: -24px;
  top: -18px;
  line-height: 20px;
  width: 41px;
  height: 41px;
  letter-spacing: 0.1px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({active}) => (active ? 'white' : `${theme.colors.brandGreenV2}`)};
  background-color: ${({active}) =>
    active ? `${theme.colors.brandGreenV2}` : `${theme.colors.greenBG}`};
  border-radius: 50%;
  opacity: 0.8;
`;

export const CatalogPreviewItemImg = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
`;

export const CatalogPreviewItemTitle = styled.p<{active?: boolean}>`
  width: 116px;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
  color: ${({active}) =>
    active ? `${theme.colors.brandGreenV2}` : `${theme.colors.darkText}`};
  word-break: break-word;
  line-height: 20px;
  /* display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden; */
  letter-spacing: 0.1px;

  &:hover {
    color: ${theme.colors.brandGreenV2};
  }
`;

export const CatalogPreviewItemWrapper = styled.div`
  display: flex;
  position: relative;
  min-width: 115px;
  width: fit-content;
  min-height: 110px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  @media (max-width: 820px) {
    width: 100%;
  }
  &:hover ${CatalogPreviewItemCount} {
    color: ${({theme}) => theme.colors.white};
    background-color: ${theme.colors.brandGreenV2};
  }

  &:hover ${CatalogPreviewItemTitle} {
    color: ${theme.colors.brandGreenV2};
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 3px;
  margin-top: 10px;
  background-color: ${theme.colors.brandGreenV2};
`;
