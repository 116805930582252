import {AnalyticEventCategories, AnalyticEventNames} from '@api/types/AnalyticEvents';
import {MainPageCategory} from '@api/types/CategoriesT';
import Button from '@components/atoms/Button';
import CatalogPreviewItem from '@components/atoms/CatalogPreviewItem';
import {GlobalContainer} from '@styles/pages/_app.styles';
import {sendClientParamsNATS} from '@utils/common';
import React, {FC} from 'react';
import {
  CatalogPreviewItems,
  CatalogPreviewTitleWrapper,
  CatalogPreviewWrapper,
  Title,
} from './CatalogPreview.styles';

interface CatalogPreviewProps {
  categories: MainPageCategory[];
  nodeRef?: React.MutableRefObject<any>;
}

const CatalogPreview: FC<CatalogPreviewProps> = ({categories, nodeRef}) => {
  return (
    <CatalogPreviewWrapper ref={nodeRef}>
      <GlobalContainer>
        <CatalogPreviewTitleWrapper>
          <Title>Лизинг техники</Title>
          <Button
            title="Весь каталог"
            variant="outlined"
            style={{
              marginRight: '3px',
              padding: '0 18px',
              fontWeight: '500',
              lineHeight: '20px',
              height: '40px',
            }}
            onClick={() => {
              sendClientParamsNATS(AnalyticEventNames.PageView, {
                eventName: AnalyticEventNames.MainPageClickGoToCatalog,
                eventCategory: AnalyticEventCategories.MainPage,
              });
            }}
            link={'/catalog'}
          />
        </CatalogPreviewTitleWrapper>
        <CatalogPreviewItems>
          {categories.map((item, i) => (
            <CatalogPreviewItem
              iconSrc={item.svgURL}
              name={item.title_alt || item.title}
              count={item.productCounter}
              key={i}
              id={+item.id_category}
              uri={item.uri}
              type="leasingCatalog"
            />
          ))}
        </CatalogPreviewItems>
      </GlobalContainer>
    </CatalogPreviewWrapper>
  );
};

export default CatalogPreview;
