import {theme} from '@styles/theme';
import styled from 'styled-components';

export const ClientServiceCardText = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: ${theme.colors.darkText};
  width: 100%;
  margin-bottom: 17px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  span {
    width: 74%;
  }
`;

export const OnlineServicesTextWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  margin-top: 22px;
`;

export const Arrow = styled.div`
  color: ${({theme}) => theme.colors.darkText};
  font-size: 14px;
  display: block;
  margin-right: 32px;
  align-self: flex-end;
  ${theme.filters.darkText}
`;

export const ClientServiceCardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background: #f3f3f3;
  border-radius: 4px;
  padding-left: 22px;
  transition: background-color 0.5s;
  cursor: pointer;
  height: 136px;
  &:hover {
    background-color: ${({theme}) => theme.colors.brandGreenV2};
    transition: background-color 0.5s;
  }
  &:hover ${ClientServiceCardText}, &:hover ${Arrow} {
    color: white;
    transition: color 0.5s;
  }
  &:hover img {
    ${theme.filters.white}
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 21px;
  margin-right: 31px;
  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`;
