import styled from 'styled-components';

import {theme} from '@styles/theme';

export const MainPageCalcWrapper = styled.div<{background?: string}>`
  background: ${p => (p.background ? p.background : `${theme.colors.lightBG}`)};

`;

export const MainPageCalcTitleWrapper = styled.div`
  margin-bottom: 20px;
`;
export const MainPageCalcTitleText = styled.div`
  font-weight: 400;
  font-size: 18px;
  color: ${({theme}) => theme.colors.gray};
  line-height: 18px;
  margin: -5px 0 20px;
  @media (max-width: 1200px) {
    font-size: 16px;
  }
  @media (max-width: 560px) {
    font-size: 15px;
  }
`;

export const MainPageCalcContainer = styled.div``;

export const MainPageCalcResultTitle = styled.div`
  display: flex;
  justify-content: end;
  letter-spacing: 0.5px;
  width: 100%;
  margin: 50px 0 20px;
  gap: 10px;
  align-items: center;
  @media (max-width: 768px) {
    gap: 20px;
  }
  @media (max-width: 560px) {
    flex-direction: column;
    gap: 10px;
  }
  h3 {
    font-family: ${theme.fonts.headline};
    color: ${theme.colors.darkText};
    text-transform: uppercase;
    line-height: 18px;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    margin: 0;
    margin-right: 20px;
  }
  p {
    color: ${theme.colors.gray};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  @media (max-width: 446px) {
    text-align: center;
  }
`;

export const MainPageCalcResultText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${theme.fonts.headline};
  font-weight: 600;
  font-size: 52px;
  letter-spacing: -0.25px;
  line-height: 60px;
  color: ${({theme}) => theme.colors.brandGreenV2};
  @media (max-width: 446px) {
    text-align: center;
  }
`;

export const AnotherText = styled.div`
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: ${theme.colors.gray};
  @media (max-width: 500px) {
    max-width: 100%;
  }
`;

export const SkeletonWrapper = styled.div`
  width: 100%;
  min-width: 225px;
  flex: none;
`;

export const ErrorMessageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
